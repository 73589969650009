import { mapActions, mapGetters } from 'vuex';

export default{
  data() {

  },
  computed: {
    ...mapGetters({
      userInfo: 'user/fetchUserInfo',
    }),
  },
  methods: {
    ...mapActions({
      follow: 'network/follow',
      createNewConversation: 'messages/createNewConversation',
      addToNetwork: 'user/userInvitationRequest',
      unFollowMember: 'network/unFollowMember',
      addRemoveSpam: 'user/addRemoveSpam',
      deleteMember: 'user/deleteMember',
      userNetwork: 'user/userNetwork',
    }),
    async followReq(customer) {
      if (!customer.follow_status.following) {
        try {
          const owner = await this.fetchUserPublicInfo();
          await this.follow({ follower: owner.url, following: customer.url });
          this.congratulationModalShow = true;
          this.networkModalText = `You are now following ${customer.user.first_name} ${customer.user.last_name}.`;
          const userIndex = this.users.findIndex(u => u.id === customer.id);
          this.users[userIndex].follow_status.following = true;
          this.$notify({
            group: 'friend',
            title: 'success',
            text: 'Member have been added to your followers list!',
            type: 'success',
          });
        } catch (e) {
          if (e.response) {
            this.networkModalText = '';
            this.congratulationModalShow = false;
            this.$notify({
              group: 'friend',
              title: 'error',
              text: `${e.response.data}`,
              type: 'error',
            });
          }
        }
      }
    },
    async UnFollowReq(customer) {
      if (customer.follow_status.following_url) {
        try {
          await this.unFollowMember(customer.follow_status.following_url);
          this.$notify({
            group: 'friend',
            title: 'success',
            text: 'Member have been deleted from your followers list!',
            type: 'success',
          });
        } catch (err) {
          this.$notify({
            group: 'friend',
            title: 'error',
            text: `${err.name}`,
            type: 'error',
          });
        }
      }
    },
    async toNetwork(customer) {
      if (!customer.friendship_status.status) {
        try {
          const owner = await this.fetchUserPublicInfo();
          if (customer.url) {
            await this.addToNetwork({
              requested_by: owner.url,
              requested_to: customer.url,
            });
            if (!!customer.follow_status.following === false) {
              await this.follow({
                follower: owner.url,
                following: customer.url,
              });
            }
            const userIndex = this.users.findIndex(u => u.id === customer.id);
            this.users[userIndex].follow_status.following = true;
          }
          this.$notify({
            group: 'friend',
            title: 'success',
            text: 'Friend request has been sent!',
            type: 'success',
          });
          this.congratulationModalShow = true;
          this.networkModalText = `Your Network request has been successfully sent to
                ${customer.user.first_name} ${customer.user.last_name}.`;
          const userIndex = this.users.findIndex(u => u.id === customer.id);
          this.users[userIndex].friendship_status.status = 'friend';
        } catch (e) {
          if (e) {
            this.networkModalText = '';
            this.congratulationModalShow = false;
            this.$notify({
              group: 'friend',
              title: 'error',
              text: `${e.message}`,
              type: 'error',
            });
          }
        }
      }
    },
    async deleteNetwork(url) {
      try {
        await this.deleteMember(url);
        this.$notify({
          group: 'friend',
          title: 'success',
          text: 'Friend have been deleted!',
          type: 'success',
        });
      } catch (err) {
        this.$notify({
          group: 'friend',
          title: 'error',
          text: `${err.message}`,
          type: 'error',
        });
      }
    },
    goToProfile(id) {
      if (id) {
        this.$router.push({ name: 'userProfilePage', params: { customerId: id } });
      }
    },
    goToNetwork() {
      this.$router.push({ name: 'network' });
    },

    isMarkedAsSpam({ id }) {
      return this.userInfo.spam_list.includes(id);
    },
    isMemberMarkedMeAsSpam({ spam_list = [] }) {
      return spam_list.includes(this.userInfo.customer_id);
    },
    async removeFromSpamList({ id, url }) {
      await this.addRemoveSpam({
        customer_id: id,
        customer: url,
        action: 'remove',
      });
    },
    async sendMsg(user) {
      try {
        const { result } = await this.createNewConversation({
          receiver_id: user.user_id || user.id,
        });
        this.$router.push({
          name: 'currentMessage',
          params: {
            chatRoomId: result.chat_room_id,
            user,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
