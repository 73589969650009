<template>
  <main>
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.employees-accordion
            variant="light"
            class="header-btn"
            >EMPLOYEES <i class="fas fa-angle-down"></i
          ></b-button>
        </b-card-header>
        <b-collapse
          id="employees-accordion"
          accordion="members-accordion"
          role="tabpanel"
          :visible='isVisibleUsers'
        >
          <b-card-body>
            <section class="networkContent">
              <div class="searchField">
                <b-form-input
                  type="text"
                  v-model="usersSearch"
                  placeholder="Search by name, surname, role or company"
                  debounce="500"
                />
                <i class="fas fa-search"></i>
              </div>
              <div id="tagField">
                <b class="tagFieldSpan">
                  <span @click="usersSearch = ''">All</span>
                </b>
                <b class="tagFieldSpan" v-for="{ id, name } in tags.slice(0, 9)" :key="id">
                  <span @click="usersSearch = name">{{ name }}</span>
                </b>
                
                <a v-if="tags.length > 9" @click="showMemberTagsModal" class="see-more">See more...</a>
              </div>
            </section>
            <section>
              <div class="selectBox">
                <b-form-select
                  v-model="selected"
                  @change="sortUsers"
                  size="sm"
                  class="mt-3"
                >
                  <option :value="null">Sort by</option>
                  <option :value="1">Name:A-Z</option>
                  <option :value="-1">Name:Z-A</option>
                </b-form-select>
              </div>
              <div class="networkUserDescSorted">
                <div
                  class="member-box"
                  v-for="customer in users"
                  :key="customer.id"
                >
                  <img
                    :src="
                      customer.profile_picture ||
                      require('../../../assets/default-user-profile-image.png')
                    "
                    alt=""
                  />
                  <div class="userInfo">
                    <span class="userName" @click="goToProfile(customer.id)">
                      {{
                        `${customer.user.first_name} ${customer.user.last_name}`
                      }}
                    </span>
                    <span class="userPosition">{{ customer.job_title }}</span>
                    <span class="userCompany" @click="getID(customer.company)">
                      {{ customer.company_name }}
                    </span>
                  </div>
                  <div
                    class="iconsForUserDesc"
                    v-show="customer.id !== userInfo.customer_id"
                  >
                    <i
                      class="fas fa-user-friends action-icon"
                      v-b-tooltip.hover
                      title="Request has been sent"
                      v-if="
                        customer.friendship_status.status === 'sent request'
                      "
                      style="cursor: default"
                    />
                    <i
                      class="fas fa-user-friends action-icon"
                      v-b-tooltip.hover
                      title="Member sent Request to you"
                      v-if="
                        customer.friendship_status.status ===
                        'pending request or you'
                      "
                      @click="goToNetwork()"
                    />
                    <i
                      class="fas fa-user-plus action-icon"
                      v-b-tooltip.hover
                      title="Add member"
                      v-if="
                        customer.friendship_status.status === '' &&
                        !isMemberMarkedMeAsSpam(customer) &&
                        !isMarkedAsSpam(customer)
                      "
                      @click="
                        toNetwork(customer),
                          (customer.friendship_status.status = 'friend'),
                          (customer.friendship_status.status = 'sent request')
                      "
                    />
                    <i
                      class="fas fa-user-times action-icon"
                      style="color: gray"
                      v-b-tooltip.hover
                      title="Unfriend Member"
                      v-if="customer.friendship_status.status === 'friend'"
                      @click="
                        deleteNetwork(customer.friendship_status.network_url),
                          (customer.friendship_status.status = '')
                      "
                    />
                    <i
                      class="fas fa-eye action-icon"
                      v-b-tooltip.hover
                      title="Follow"
                      v-if="!!customer.follow_status.following === false"
                      @click="
                        followReq(customer),
                          (customer.follow_status.following = true)
                      "
                    />
                    <i
                      class="fas fa-eye-slash action-icon"
                      v-b-tooltip.hover
                      title="Unfollow"
                      v-else
                      @click="
                        UnFollowReq(customer),
                          (customer.follow_status.following = false)
                      "
                    />
                    <i
                      v-if="isMarkedAsSpam(customer)"
                      class="fas fa-info-circle action-icon"
                      v-b-tooltip.hover
                      title="Unblock"
                      @click="removeFromSpamList(customer)"
                    />
                    <i
                      v-else-if="!isMemberMarkedMeAsSpam(customer)"
                      class="fas fa-envelope action-icon"
                      v-b-tooltip.hover
                      title="Message"
                      @click="sendMsg(customer.user)"
                    />
                  </div>
                </div>
                <infinite-loading
                  @infinite="usersInfiniteHandler"
                  :identifier="usersInfiniteHandlerId"
                >
                  <div slot="no-more" />
                  <div slot="spinner" />
                  <div slot="no-results" />
                </infinite-loading>
              </div>
            </section>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.companies-accordion
            variant="light"
            class="header-btn"
            >COMPANIES <i class="fas fa-angle-down"></i
          ></b-button>
        </b-card-header>
        <b-collapse
          id="companies-accordion"
          accordion="members-accordion"
          role="tabpanel"
          :visible="isVisibleCompanies"
        >
          <b-card-body>
            <section class="networkContent">
              <div class="searchField">
                <b-form-input
                  type="text"
                  v-model="companiesSearch"
                  placeholder="Search by name, country or company type"
                  debounce="500"
                />
                <i class="fas fa-search"></i>
              </div>
              <div id="tagField">
                <b class="tagFieldSpan">
                  <span @click="getAllCompaniesList">All</span>
                </b>
                <b
                  class="tagFieldSpan"
                  v-for="{ id, name } in companyTags"
                  :key="id"
                >
                  <span @click="companiesSearch = name">{{ name }}</span>
                </b>
              </div>
            </section>
            <section>
              <div class="selectBox">
                <b-form-select
                  v-model="companySortSelected"
                  @change="sortCompanies"
                  size="sm"
                  class="mt-3"
                  ><option :value="null">Sort by</option>
                  <option :value="1">Name:A-Z</option>
                  <option :value="-1">Name:Z-A</option>
                </b-form-select>
              </div>
              <div class="networkUserDescSorted">
                <div
                  class="company-box"
                  v-for="(company, i) in allCompanies"
                  :key="`${company.id}-${i}`"
                >
                  <img
                    :src="
                      company.logo || require('@/assets/default-company.jpg')
                    "
                    alt=""
                  />
                  <div class="membersListCompanyInfo">
                    <span
                      class="userName"
                      @click="goToCompanyProfile(company.id)"
                    >
                      {{ company.name }}
                    </span>
                    <span class="userPosition">{{ company.company_type }}</span>
                  </div>
                  <div
                    class="iconsForCompanyDesc"
                    v-if="company.url !== userInfo.company"
                  >
                    <i
                      v-if="!!company.follow_status === false"
                      class="fas fa-eye companyEyeIcon action-icon"
                      v-b-tooltip.hover
                      title="Follow"
                      @click="
                        companyFollow(company), (company.follow_status = true)
                      "
                    >
                    </i>
                    <i
                      v-if="!!company.follow_status === true"
                      class="fas fa-eye-slash companyEyeIcon action-icon"
                      v-b-tooltip.hover
                      title="Unfollow"
                      @click="
                        companyUnFollow(company),
                          (company.follow_status = false)
                      "
                    >
                    </i>
                  </div>
                </div>
            <infinite-loading
              @infinite="companiesInfiniteHandler"
              :identifier="companyInfiniteHandlerId"
            >
              <div slot="no-more" />
              <div slot="spinner"/>
              <div slot="no-results" />
            </infinite-loading>
              </div>

            </section>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <b-modal
      v-model="congratulationModalShow"
      size="md"
      hide-footer
      @click="
        congratulationModalShow = false;
        networkModalText = '';
      "
    >
      <h3 style="text-align: center">CONGRATULATIONS!</h3>
      <p style="text-align: center">{{ networkModalText }}</p>
      <div class="congratulationModal">
        <router-link :to="{ name: 'network' }">My Network</router-link>
        <button
          @click="
            congratulationModalShow = false;
            networkModalText = '';
          "
        >
          Continue
        </button>
      </div>
    </b-modal>
    <notifications group="friend" position="bottom left" />
    <MemberMoreTagModal @changeSearchParam="handleChangeSearchParam" :tags="tags" ref="member-tags-modal" />
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import getIDfromURL from '../../../utils/getIDfromURL';
import memberFunctions from '../../../utils/mixins/memberFunctions';
import MemberMoreTagModal from './MemberMoreTagModal.vue';

export default {
  name: 'MembersList',
  mixins: [memberFunctions],
  components: {
    MemberMoreTagModal,
  },
  data() {
    return {
      selected: null,
      companySortSelected: null,
      userPage: 1,
      usersCount: null,
      usersNextPage: true,
      page: 1,
      congratulationModalShow: false,
      networkModalText: '',
      usersSearch: null,
      users: [],
      companiesSearch: null,
      companyPage: 1,
      companiesNextPage: true,
      companiesCount: null,
      allCompanies: [],
      companyTags: [],
      userChatData: [],
      companyInfiniteHandlerId: uuidv4(),
      usersInfiniteHandlerId: uuidv4(),
    };
  },
  computed: {
    ...mapGetters({
      tags: 'tags/fetchItems',
      allCompaniesList: 'companies/allCompaniesList',
    }),
    isVisibleUsers() {
      return !!this.$route.query.user;
    },
    isVisibleCompanies() {
      return !!this.$route.query.company;
    },
  },
  async mounted() {
    const { user } = this.$route.query;
    const { company } = this.$route.query;
    if (user) {
      this.usersSearch = user;
      this.users = await this.searchCustomer(user);
    } else {
      await this.getUsers();
    }

    if (company) {
      this.companiesSearch = company;
      this.allCompanies = await this.searchOnCompaniesList(company);
    } else {
      await this.getCompanies();
    }

    await this.fetchTags();
    await this.fetchUnreadNotifications();
    await this.fetchInvitationRequests();
    this.companyTags = await this.companySettingsSelects('areas_of_interest');
  },
  watch: {
    usersSearch: {
      async handler(searchValue) {
        if (!searchValue) {
          this.userPage = 1;
          this.users = [];
          await this.getUsers();
          return;
        }
        this.$router.replace({ name: 'MembersList', query: { user: this.usersSearch } });
        this.users = await this.searchCustomer(searchValue);
      },
    },
    companiesSearch: {
      async handler(searchValue) {
        if (!searchValue) {
          this.companyPage = 1;
          this.allCompanies = [];
          await this.getCompanies();
          return;
        }
        this.$router.replace({ name: 'MembersList', query: { company: this.companiesSearch } });
        this.allCompanies = await this.searchOnCompaniesList(searchValue);
      },
    },
  },
  methods: {
    ...mapActions({
      fetchAllUsers: 'user/fetchAllUsers',
      fetchTags: 'tags/fetchList',
      fetchUserPublicInfo: 'user/fetchUserPublicInfo',
      followUnfollowCompany: 'companies/followUnfollowCompany',
      fetchCompaniesList: 'companies/fetchCompaniesList',
      searchOnCompaniesList: 'companies/searchOnCompaniesList',
      searchCustomer: 'user/searchCustomer',
      fetchAllCompanies: 'companies/fetchAllCompanies',
      companySettingsSelects: 'auth/companySettingsSelects',
      fetchUnreadNotifications: 'notifications/fetchUnreadNotifications',
      fetchInvitationRequests: 'user/fetchInvitationRequests',
    }),
    async getAllCompaniesList() {
      await this.fetchAllCompanies().then(() => {
        this.allCompanies = this.allCompaniesList;
        this.companyPage = 1;
      });
    },
    async getCompanies() {
      const { results, next, count } = await this.fetchCompaniesList(
        this.companyPage,
      );
      this.companiesNextPage = !!next;
      this.companiesCount = count;
      this.companyPage += 1;
      this.allCompanies = [
        ...this.allCompanies,
        ...results,
      ];
    },
    async getUsers() {
      const { results, next, count } = await this.fetchAllUsers(this.userPage);
      this.usersNextPage = !!next;
      this.usersCount = count - 1;
      this.userPage += 1;
      this.users = [
        ...this.users,
        ...results,
      ].filter(
        user => user.id !== this.userInfo.customer_id && user.user.is_active,
      );
    },
    async usersInfiniteHandler($state) {
      try {
        if (this.usersNextPage) {
          await this.getUsers();
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (err) {
        console.log('err users infinite', err);
        $state.complete();
      }
    },
    async companiesInfiniteHandler($state) {
      try {
        if (this.companiesNextPage) {
          await this.getCompanies();
          $state.loaded();
        } else {
          $state.complete();
        }
      } catch (err) {
        console.log('err companies infinite', err);
        $state.complete();
      }
    },
    sortUsers(value) {
      if (value) {
        const secondValue = value * -1;
        this.users.sort((a, b) => (a.user.first_name > b.user.first_name ? value : secondValue));
      }
    },
    sortCompanies(value) {
      if (value) {
        const secondValue = value * -1;
        this.allCompanies.sort((a, b) => (a.name > b.name ? value : secondValue));
      }
    },
    getID(companyUrl) {
      this.goToCompanyProfile(getIDfromURL(companyUrl));
    },
    goToCompanyProfile(id) {
      if (id) {
        const companyId = `${id}`;
        this.$router.push({ name: 'companyProfile', params: { companyId } });
      }
    },
    companyFollow(company) {
      try {
        if (company.url) {
          this.followUnfollowCompany({
            action: 'follow',
            company: company.url,
          });
          this.networkModalText = `
          You are now following ${company.name}.`;
          this.congratulationModalShow = true;
        }
        this.$notify({
          group: 'friend',
          title: 'success',
          text: 'Follow request has been sent!',
          type: 'success',
        });
      } catch (err) {
        this.networkModalText = '';
        this.congratulationModalShow = false;
        this.$notify({
          group: 'friend',
          title: 'error',
          text: `${err.name}`,
          type: 'error',
        });
      }
    },
    companyUnFollow(company) {
      try {
        if (company.url) {
          this.followUnfollowCompany({
            action: 'unfollow',
            company: company.url,
          });
        }
        this.$notify({
          group: 'friend',
          title: 'success',
          text: 'Unfollow request has been sent!',
          type: 'success',
        });
      } catch (err) {
        this.$notify({
          group: 'friend',
          title: 'error',
          text: `${err.name}`,
          type: 'error',
        });
      }
    },
    showMemberTagsModal() {
      this.$refs['member-tags-modal'].$refs['tags-modal'].show();
    },
    handleChangeSearchParam(value) {
      this.usersSearch = value;
      this.$refs['member-tags-modal'].$refs['tags-modal'].hide();
    },
  },
};
</script>

<style scoped src='./MembersList.css'></style>
