<template>
	<b-modal ref="tags-modal" hide-footer title="Filter users by tags">
		<b-container>
			<div id="tagField">
				<b class="tagFieldSpan">
					<span @click="() => changeSearchParam('')">All</span>
				</b>
				<b class="tagFieldSpan" v-for="{ id, name } in tags" :key="id">
					<span @click="() => changeSearchParam(name)">{{ name }}</span>
				</b>
			</div>
		</b-container>
	</b-modal>
</template>

<script>
export default {
	name: 'MemberMoreTagModal',
	props: {
		tags: {
			type: Array,
			default: () => [],
		},
	},
	methods: {
		changeSearchParam(name) {
			this.$emit('changeSearchParam', name);
		},
	},
};
</script>

<style scoped>
#tagField {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 1.5%;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.tagFieldSpan {
  border-radius: 0 10px 10px 10px;
  font-weight: normal;
  background-color: #aaaaaa;
  font-size: 12px;
  color: white;
  padding: 4px 8px;
  margin: 2px;
  cursor: pointer;
}
</style>